import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import router from "../router";
import store from "../store";

export default class UserDataService {
    static async getUser(msisdn) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.fantasyHost}/login/get-user`,
            data: {
                msisdn
            }
        })
    }

    static async user(token, revenuePartnerId) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.upstreamIntegrationService}/user`,
            data: {
                token,
                revenuePartnerId: revenuePartnerId
            }
        });
    }

    static async updateToken(token) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.upstreamIntegrationService}/updateToken`,
            data: {
                token: token
            }
        });
    }

    static async headerEnrichedLogin(encryptedMsisdn) {
        const response = await axios({
            method: 'post',
            url: `${environmentConfig.upstreamIntegrationService}/user-authentication/encrypted-msisdn-login`,
            data: {
                encryptedMsisdn,
            }
        });

        return response.data.token;
    }

    static async getUserStatus(token) {
        const user = await UserDataService.invokeGetUserStatus(token);
        if (user.data.token === 'invalidToken') {
            localStorage.removeItem("token");
            if (store.state.brandConfig.brand === 'vc-za-en') window.open(`${environmentConfig.entryPortalHost}`, '_self');
            else return router.push(`/login`);
        }
        return user
    }

    static async invokeGetUserStatus(token) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/get-user-status`, {
            token
        })
    }

    static async getUserStats(msisdn) {
        return await axios.post(`${environmentConfig.fantasyHost}/profile/get-user-stats`, {
            msisdn: msisdn
        })
    }

    static async getUserRoundEntries(roundId, userId, userToken) {
        return axios.post(`${environmentConfig.fantasyHost}/soccer/get-leaderboard-user-entries`, {
            id: roundId,
            userId: userId,
            userToken: userToken
        })
    }

    static async getUserEntries(roundId, msisdn) {
        return axios.post(`${environmentConfig.fantasyHost}/soccer/get-user-entries`, {
            id: roundId,
            msisdn: msisdn
        })
    }

    static async postUserEntries(collection, userAccessId, msisdn) {
        await this.deleteUserEntries(collection[0].roundId, msisdn)
        return axios.post(`${environmentConfig.fantasyHost}/soccer/post-user-entries`, {
            collection: collection,
            userAccessId,
            msisdn: msisdn
        })
    }

    static async deleteUserEntries(roundId, msisdn) {
        return axios.post(`${environmentConfig.fantasyHost}/soccer/delete-user-entries`, {
            roundId,
            msisdn
        })
    }

    static async autoPickOptIn(userToken, msisdn, isOptIn) {
        return axios.post(`${environmentConfig.fantasyHost}/soccer/auto-pick-opt-in`, {
            token: userToken,
            isOptIn: isOptIn,
            msisdn: msisdn
        })
    }

    static async updateUserData(msisdn, newUsername, serviceMessages) {
        return axios.post(`${environmentConfig.fantasyHost}/profile/update-user-data`, {
            contentMessages: null,
            onceOffAccessExpiresAt: null,
            msisdn: msisdn,
            username: newUsername,
            serviceMessages: serviceMessages
        })
    }

    static async subscribe(msisdn, sessionId, revenuePartnerId, serviceId, scheme) {
        const chnl = localStorage.getItem('channel')
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/v2/subscription`,
            {
                msisdn: msisdn,
                revenuePartnerId: `${revenuePartnerId}`,
                action: 'Subscription',
                sessionId: sessionId,
                serviceId: serviceId,
                scheme: scheme,
                chnl
            });
    }

    static async unsubscribe(msisdn, sessionId, revenuePartnerId, serviceId, scheme) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/v2/subscription`,
            {
                msisdn: msisdn,
                revenuePartnerId: `${revenuePartnerId}`,
                action: 'Cancellation',
                sessionId: sessionId,
                serviceId: serviceId,
                scheme: scheme
            });
    }

    static async validateUsername(username, userToken) {
        return axios.post(`${environmentConfig.upstreamIntegrationService}/user-authentication/validate-username`, {
            username: username,
            userToken: userToken
        })
    }
}
